import { useStaticQuery, graphql } from "gatsby"

const useJobs = language => {
  const data = useStaticQuery(graphql`
    query Languages {
      jp: allStrapiJobs(
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
        sort: { fields: DatePublication, order: DESC }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          published_at(formatString: "YYYY年 MM月 DD日")
          strapiId
          MandatoryRequirement
          WelcomeRequirement
          NewGraduates {
            Imposible
            Negotiable
            Posible
          }
          VisaSupport {
            None
            ThereRegulation
            Yes
          }
          JobCategorie
          LocationDescription
          DatePublication
          locations {
            name
            # location_groups {
            #   SubLocation
            # }
          }
          marks {
            name
          }
          types {
            name
            image {
              url
            }
          }
        }
      }
      en: allStrapiJobs(
        filter: { Archived: { eq: false }, Language: { eq: "English" } }
        sort: { fields: DatePublication, order: DESC }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          published_at(formatString: "YYYY年 MM月 DD日")
          strapiId
          MandatoryRequirement
          WelcomeRequirement
          NewGraduates {
            Imposible
            Negotiable
            Posible
          }
          VisaSupport {
            None
            ThereRegulation
            Yes
          }
          JobCategorie
          LocationDescription
          DatePublication
          locations {
            name
            # location_groups {
            #   SubLocation
            # }
          }
          marks {
            name
          }
          types {
            name
            image {
              url
            }
          }
        }
      }
      es: allStrapiJobs(
        filter: { Archived: { eq: false }, Language: { eq: "Spanish" } }
        sort: { fields: DatePublication, order: DESC }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          published_at(formatString: "YYYY年 MM月 DD日")
          strapiId
          MandatoryRequirement
          WelcomeRequirement
          NewGraduates {
            Imposible
            Negotiable
            Posible
          }
          VisaSupport {
            None
            ThereRegulation
            Yes
          }
          JobCategorie
          LocationDescription
          DatePublication
          locations {
            name
            # location_groups {
            #   SubLocation
            # }
          }
          marks {
            name
          }
          types {
            name
            image {
              url
            }
          }
        }
      }
    }
  `)

  return language === "Japanese"
    ? data.jp.nodes
    : language === "English"
    ? data.en.nodes
    : data.es.nodes
}

export default useJobs
